const axios = require('axios');
import authHeader from './auth-header';

class DataService {

  updateProjekt(project) {
    return new Promise((resolve, reject) => {
     /* try { */
        const resp = axios({
          method: 'put',
          url: '/api/projekte/',
          data: project,
          headers: authHeader()
        })
        .then(function () {
          resolve(resp)
        })
        .catch(function (error) {
          reject(error)
        });
        /*
        console.log("********************FAILE3D*************************************")
        console.log(resp)
        resolve(resp);
      } catch (err) {
        console.log("********************FAILE4D*************************************")
        reject(err);
      }
      */
    });
  }
  deleteProjekt(project) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'delete',
          url: '/api/projekte/',
          data: project,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }
  createProjekt(project) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'post',
          url: '/api/projekte/',
          data: project,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }
  updateZahlung(aktuelleZahlung) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'put',
          url: '/api/zahlung/',
          data: aktuelleZahlung,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteZahlung(aktuelleZahlung) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'delete',
          url: '/api/zahlung/',
          data: aktuelleZahlung,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  createZahlung(aktuelleZahlung) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'post',
          url: '/api/zahlung/',
          data: aktuelleZahlung,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  getAlleProjekte() {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'get',
          url: '/api/projekte',
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  getAlleProjekteActive() {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'get',
          url: '/api/projekte-active',
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  getProjektDetail(id) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'get',
          url: '/api/projekte/' + id,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  getAlleMitglieder() {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'get',
          url: '/api/mitglieder',
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
    
  }
  deleteMitglied(mitglied) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'delete',
          url: '/api/mitglieder/',
          data: mitglied,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }
  getMitgliedDetail(id) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'get',
          url: '/api/mitglieder/' + id,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }
  createMitglied(mitglied) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'post',
          url: '/api/mitglieder/',
          data: mitglied,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  restoreBackup(backup) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'post',
          url: '/api/backup/',
          data: backup,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }

  restoreMitgliederBackup(backup) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'post',
          url: '/api/mitgliederbackup/',
          data: backup,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }
  
  updateMitglied(mitglied) {
    return new Promise((resolve, reject) => {
      try {
        const resp = axios({
          method: 'put',
          url: '/api/mitglieder/',
          data: mitglied,
          headers: authHeader()
        })
        resolve(resp);
      } catch (err) {
        reject(err);
      }
    });
  }
}

export default new DataService;